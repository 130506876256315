<script lang="ts">
    import {
        Accordion,
        AccordionItem,
        Alert,
        Box,
        Button,
        Col,
        Container,
        Content,
        Heading,
        Hero,
        Section,
        TabItem,
        Tabs,
    } from '@nomodo/ui'
    import Row from '@nomodo/ui/src/lib/atoms/Row.svelte'
    import { createMutation } from '@tanstack/svelte-query'
    import { MetaTags } from 'svelte-meta-tags'

    import AppTiles from '$lib/components/AppTiles.svelte'
    import FormContact from '$lib/components/FormContact.svelte'
    import DefaultLayout from '$lib/layouts/Default.svelte'
    import { type ContactParams, submitContact } from '$lib/mutations'
    import { getTranslatedErrorMessage } from '$lib/utils'

    import type { PageData } from './$types'

    export let data: PageData
    const mutation = createMutation<void, Error, ContactParams>({
        mutationFn: submitContact,
    })
</script>

<MetaTags
    canonical={`${import.meta.env.VITE_SITE_URL}`}
    description="Expand your offer with hundreds of applications (DB, CMS, CRM, Run-time environment and more) that your customers can launch with a single click. All white-labeled under your brand and on your servers."
    facebook={{
        appId: import.meta.env.VITE_FB_ID,
    }}
    openGraph={{
        type: 'website',
        url: `${import.meta.env.VITE_SITE_URL}`,
        title: 'Deliver AWS-like services on your Infrastructure and under your Brand',
        description:
            'Expand your offer with hundreds of applications (DB, CMS, CRM, Run-time environment and more) that your customers can launch with a single click. All white-labeled under your brand and on your servers.',
        images: [
            {
                url: `${
                    import.meta.env.VITE_CLOUDINARY_URL
                }/v1692520230/open-graph/nomodo-deliver-aws-like-services.png`,
                width: 1200,
                height: 527,
                alt: 'Deliver AWS-like services on your Infrastructure and under your Brand',
            },
        ],
        siteName: 'nomodo.io',
    }}
    title="Deliver AWS-like services on your Infrastructure and under your Brand"
    twitter={{
        cardType: 'summary_large_image',
        title: 'Deliver AWS-like services on your Infrastructure and under your Brand',
        description:
            'Expand your offer with hundreds of applications (DB, CMS, CRM, Run-time environment and more) that your customers can launch with a single click. All white-labeled under your brand and on your servers.',
        image: `${
            import.meta.env.VITE_CLOUDINARY_URL
        }/v1692520230/open-graph/nomodo-deliver-aws-like-services.png`,
        imageAlt:
            'Deliver AWS-like services on your Infrastructure and under your Brand',
    }}
/>

<DefaultLayout>
    <Hero data-cy="hero">
        <svelte:fragment slot="preheading">
            For hosting providers
        </svelte:fragment>
        <svelte:fragment slot="heading">
            Deliver AWS-like services on
            <span>your existing infrastructure</span> <br />
            and boost your revenue
        </svelte:fragment>

        <div
            slot="content"
            class="flex w-full flex-col items-start lg:flex-row"
        >
            <div class="col lg:w-7/12">
                <div class="pr-8 lg:pr-0">
                    <p class="mb-8 text-xl leading-8 md:leading-10">
                        Expand your offer with <strong
                            >hundreds of applications</strong
                        >
                        (DB, CMS, CRM, Run-time environment and more) that your customers
                        can launch with a single click.
                        <strong>All&#160;white-labeled</strong>
                        under <strong>your brand</strong> and on
                        <strong>your servers</strong>.
                    </p>

                    <Button href="/#contact" size="lg" variant="success">
                        Get in touch
                    </Button>
                </div>
            </div>
        </div>
    </Hero>

    <Section id="how-it-works" variant="light">
        <Container>
            <Heading class="mb-6" level="2">
                Get your own branded <br />application store
            </Heading>

            <p class="mb-10">
                Select the apps you want to add to your portfolio and start
                selling.<br /> Attract a new segment of customers and maximize the
                capacity of your infrastructure.
            </p>
            <Tabs>
                <TabItem id="run-time" active>
                    <svelte:fragment slot="tab">
                        Run-time environment
                    </svelte:fragment>
                    <AppTiles apps={data.apps.runtime} />
                </TabItem>
                <TabItem id="dev-tools">
                    <svelte:fragment slot="tab">
                        Developers tools
                    </svelte:fragment>
                    <AppTiles apps={data.apps.tools} />
                </TabItem>
                <TabItem id="crm-cms">
                    <svelte:fragment slot="tab">
                        CRM, CMS and other
                    </svelte:fragment>
                    <AppTiles apps={data.apps.other} />
                </TabItem>
            </Tabs>
        </Container>
    </Section>

    <Section id="solution" variant="white">
        <Container>
            <Heading class="mb-6" level="2">
                Beat Public Clouds <br />with your offers
            </Heading>
            <p class="mb-10">
                With nomodo.io, you can beat the competition from public clouds
                like AWS, Azure, and GCP <br />by offering not just VPS hosting,
                but also hosting for these open source services and pricing them
                competitively.
            </p>

            <Heading class="mb-6" level="3" size="4">
                Pricing comparision
            </Heading>
            <div class="relative mb-10 overflow-x-auto">
                <table class="w-full text-left text-sm">
                    <thead class="bg-gray-100 text-xs uppercase">
                        <tr>
                            <th class="px-3 py-3" scope="col">
                                Average VPS<br />provider
                            </th>
                            <th class="px-3 py-3" scope="col">
                                Heroku Postgres<br />(Private 2)
                            </th>
                            <th class="px-3 py-3" scope="col">
                                Heroku Kafka<br />(Private 0)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b">
                            <td class="px-3 py-2">
                                4 CPU<br />8 GB RAM<br />200 GB Storage
                            </td>
                            <td class="px-3 py-2">
                                8 GB RAM<br />256 GB Storage
                            </td>
                            <td class="px-3 py-2">
                                4 CPU<br />16 GB RAM<br />150 GB Storage
                            </td>
                        </tr>
                        <tr class="border-b font-bold">
                            <td class="px-3 py-2"> 73 USD</td>
                            <td class="px-3 py-2">635 USD</td>
                            <td class="px-3 py-2">1,986 USD</td>
                        </tr>
                        <tr class="border-b text-lg">
                            <td class="px-3 py-2">Additional profit</td>
                            <td class="px-3 py-2 font-bold text-green-600">
                                +562 USD
                            </td>
                            <td class="px-3 py-2 font-bold text-green-600">
                                +1,917 USD
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Button href="/#contact" variant="success">Get in touch</Button>
        </Container>
    </Section>

    <Section id="partnership" variant="light">
        <Container>
            <Heading class="mb-6" level="2">Partnership at no cost</Heading>
            <Col class="mb-10" lg="9">
                <p class="mb-4">
                    There are no monthly fees or any other fixed fees. You only
                    need to integrate your systems with the Nomodo API. This
                    integration grants you the ability to offer and deploy
                    applications.
                </p>

                <p>
                    You will handle distribution, while Nomodo takes care of the
                    app store and all technical updates. Together, we will share
                    the profits from hosting the apps. It’s a win-win-win
                    situation for everyone involved.
                </p>
            </Col>

            <Heading class="mb-6" level="3">How it works</Heading>

            <Row>
                <Col class="mb-8 lg:mb-0" lg="6">
                    <Heading class="mb-6" fontFamily="sans" level="4">
                        <span class="block text-gray-400">
                            Simplified Integration
                        </span>
                        for hosting providers — partners
                    </Heading>
                    <ol>
                        <li
                            class="relative mb-4 pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['1']"
                        >
                            <strong class="block font-display text-2xl">
                                Portfolio Expansion
                            </strong>
                            <span class="leading-6">
                                Decide which apps you want to feature and
                                enhance your service offerings.
                            </span>
                        </li>
                        <li
                            class="relative mb-4 pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['2']"
                        >
                            <strong class="block font-display text-2xl">
                                Reach Out
                            </strong>
                            <span class="leading-6">
                                Contact us for a seamless collaboration
                                experience.
                            </span>
                        </li>
                        <li
                            class="relative pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['3']"
                        >
                            <strong class="block font-display text-2xl">
                                Integration Choices
                            </strong>
                            <span class="leading-6">
                                Whether it’s through our API or PROXMOX
                                integration, we ensure a smooth setup process.
                            </span>
                        </li>
                    </ol>
                </Col>
                <Col lg="6">
                    <Heading class="mb-6" fontFamily="sans" level="4">
                        <span class="block text-gray-400">
                            A Hassle-Free Experience
                        </span>
                        for your clients — end users
                    </Heading>
                    <ol>
                        <li
                            class="relative mb-4 pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['1']"
                        >
                            <strong class="block font-display text-2xl">
                                Browse apps
                            </strong>
                            <span class="leading-6">
                                Select from an extensive list of databases, load
                                balancers, monitoring tools, CMS, CRM, and more.
                            </span>
                        </li>
                        <li
                            class="relative mb-4 pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['2']"
                        >
                            <strong class="block font-display text-2xl">
                                Deploy
                            </strong>
                            <span class="leading-6">
                                With just one click, have your chosen app ready
                                and fully configured.
                            </span>
                        </li>
                        <li
                            class="relative pl-12 before:absolute before:-top-[.5] before:left-0 before:inline-block before:h-8 before:w-8 before:rounded before:bg-gray-200 before:text-center before:font-display before:text-xl before:leading-[1.5] before:text-white before:content-['3']"
                        >
                            <strong class="block font-display text-2xl">
                                Use <span class="text-gray-300" />
                            </strong>
                            <span class="leading-6">
                                Dive right in! Access the app via the web
                                interface or integrate it into your workflow
                                using connection strings.
                            </span>
                        </li>
                    </ol>
                </Col>
            </Row>
            <Button class="mt-10" href="/#contact" variant="success">
                Get in touch
            </Button>
        </Container>
    </Section>

    <Section variant="dark">
        <Container>
            <Col md="6">
                <Heading id="contact" class="mb-6" level="2">
                    Let's talk
                </Heading>
                <p class="mb-10">
                    Book a call with us and discuss how Nomodo App Store can
                    expand your service offer and boost your revenue per
                    customer.
                </p>
                {#if $mutation.error}
                    <Alert class="mb-4" variant="danger">
                        {@html getTranslatedErrorMessage(
                            $mutation.error.message
                        )}
                    </Alert>
                {/if}

                {#if $mutation.isSuccess}
                    <Alert class="mb-4" variant="success">
                        Thank you for your message!
                    </Alert>
                {:else}
                    <Box variant="light">
                        <FormContact
                            loading={$mutation.isPending}
                            on:submit={(e) =>
                                $mutation.mutate({
                                    e,
                                    component: 'contact-form',
                                    slug: 'contact',
                                })}
                        />
                    </Box>
                {/if}
            </Col>
        </Container>
    </Section>

    <Section variant="white">
        <Container>
            <Heading id="faq" class="mb-6" level="2">Got questions?</Heading>
            <div class="grid md:grid-cols-2 md:gap-16">
                {#each data.faqs as category}
                    <div>
                        <Heading class="mb-3" fontFamily="sans" level="3">
                            {category.name}
                        </Heading>
                        <div class="mb-7">
                            <Accordion multiselect>
                                {#each category.items as faq}
                                    {#if faq.show}
                                        <AccordionItem title={faq.name}>
                                            <Content>
                                                {@html faq.body}
                                            </Content>
                                        </AccordionItem>
                                    {/if}
                                {/each}
                            </Accordion>
                        </div>
                    </div>
                {/each}
            </div>
        </Container>
    </Section>
</DefaultLayout>
